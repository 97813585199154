@use "src/theme";

.control {
  padding: var(--length-padding);
  border-radius: var(--color-input-radius);
  background: var(--color-input);
  border: 1px solid var(--color-border);
  text-overflow: ellipsis;
  color: var(--color-dark);

  &:not(textarea) {
    white-space: nowrap;
}

  &[data-invalid] {
    background: color-mix(in srgb, var(--color-input, #fff), var(--color-danger) 5%);
    --color-ring: var(--color-danger);
  }

  &:disabled {
    color: var(--color-muted-text);
    cursor: not-allowed !important;
   background: var(--color-input-disabled);
  }
}

.picture {
  overflow: hidden;

  &[data-loading="true"] {
    img {
      filter: blur(5px);
    }
  }

  &[data-fit="fill"] {
    img {
      width: 100%;
      max-height: 100%;
      height: 100% !important;
    }
  }

  &[data-fit="contain"] {
    img {
      // for safari
      min-width: intrinsic;
    }
  }

  &[data-fit="cover"] {
    img {
      width: 100%;
      max-height: none !important;
      max-width: none !important;
      height: 100%;
      object-fit: cover;
    }
  }



}

.root {
  display: inline-block;
  position: relative;
  height: 1lh;
  width: 1.5em;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  translate: -50% -50%;

  > svg {
    width: 1em;
    height: 1em;
  }
}

.group {
  display: inline-flex;
  border-radius: var(--length-radius);
  border: 1px solid var(--color-border);
  width: max-content;
  height: max-content;

  > [data-radix-collection-item] {
    border-radius: var(--length-radius);
    background: var(--color-muted-light);
    color: var(--color-foreground);
    --color-button: var(--color-input);
    --color-button-foreground: var(--color-foreground);
    --color-ring: var(--color-primary);

    &:focus {
      z-index: 1;
    }
  }

  > [data-state='on'] {
    background: white;
    color: var(--color-primary);
  }

  > [data-radix-collection-item]:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 0.525em;
  }

  > [data-radix-collection-item]:not(:last-child) {
    padding-right: 0.525em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  &[aria-disabled='true'] {
    .item {
      color: var(--color-muted-text);
    }
  }
}



.root {
}

.item {
}

.item:first-child {
  margin-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.item:focus-within {
  position: relative;
  z-index: 1;
}

.header {
  display: flex;
}

.trigger {
  cursor: s-resize;

  &[data-state='open'] {
    cursor: n-resize;
  }
}

.trigger:hover {
}

.content {
  overflow: hidden;
}

.content[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.content[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  padding: 15px 20px;
}

.AccordionChevron {
  color: var(--violet-10);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.content[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}


.indicator {
transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);

  [data-state="open"] & {
  transform: rotate(180deg);
}
}

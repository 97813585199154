@use "src/theme";

.imageUpload {
  height: 400px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;

  img {
    min-height: 200px;
  }

  &.loading {
    cursor: wait;
    animation: loading 300ms infinite alternate;

    & > * {
      pointer-events: none;
    }
  }

  :global(.ReactCrop), :global(.ReactCrop__child-wrapper), img {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
  }
}

.dropZone {
  &[data-dragging="true"] {
    background: var(--color-muted-light);
  }

  &:focus {
    outline: none;
    border-color: var(--color-ring);
  }
}

@keyframes loading {
  from {
    opacity: 0.8;
  }

  to {
    opacity: 0.5;
  }

}

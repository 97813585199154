.cookieManager {
font-size: 0.9rem;
display: flex;
  flex-direction: column;

  >div {

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-border);
    }
  }

    th {
      background-color: var(--color-background);
      border-bottom: 1px solid var(--color-border);
      padding: 0.5rem 1rem;
      text-align: left;
    }

    td {
      border-bottom: 1px solid var(--color-border);
      padding: 0.5rem 1rem;
    }
}
